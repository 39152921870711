import React from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";

interface IBreadcrumbProps {
  currentPageTitle: string;
  parentTitle: string;
}

export default function Breadcrumb({ currentPageTitle, parentTitle }: IBreadcrumbProps) {
  const { t } = useTranslation();

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link href="/">FormPay</Link>
        </li>
        <li className="breadcrumb-item">
          <Link href="/blog">{t(parentTitle)}</Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {currentPageTitle}
        </li>
      </ol>
    </nav>
  );
}