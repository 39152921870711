import React from "react";
import Link from 'next/link';

interface IPromoBanner {
  content: {
    route: string;
    text: string;
  }
}

export default function PromoBanner( { content }: IPromoBanner){
  return (
    <div className="promo-banner">
      <div className="container">
        <Link href={content.route}>
          <a className="promo-banner__link">
            {content.text}
          </a>
        </Link>
      </div>
    </div>
  );
}
