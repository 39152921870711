import React from "react";
import Link from 'next/link';

const footerContent = [{
  title: "Sell Online",
  links: [{
    name: "Sell food",
    url: "/food"
  },{
    name: "Sell art and crafts",
    url: "/art"
  },{
    name: "Sell registrations",
    url: "/registrations"
  }]
}, {
  title: "Resources",
  links: [{
    name: "Blog",
    url: "/blog"
  },{
    name: "Login",
    url: "/login"
  }]
},{
  title: "Company",
  links: [{
    name: "Terms of Service",
    url: "/terms"
  },{
    name: "Privacy Policy",
    url: "/privacy"
  }]
}]

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <ul className="list-unstyled row">
          {footerContent.map((content, index) => ( 
            <li className="col-sm-6 col-md-4 footer__list-block" key={index}>
              <span className="footer__heading">{content.title}</span>
              <ul className="list-unstyled">
                {content.links.map((link, index) => (
                  <li className="footer__list-block-item" key={index}>
                    <Link href={link.url}>{link.name}</Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
