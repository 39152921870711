import React, { ReactElement } from "react";

interface IBackgroundProps {
  children: ReactElement;
  classes?: string;
}

export default function Background({ children, classes }: IBackgroundProps) {
  return <div className={`background ${classes ? classes : ""}`}>{children}</div>;
}
