import React from "react";
import Image from "next/image";
import Link from "next/link";

import { FormPayLogo } from "../../images";

interface IBrandLogoProps {
  classes: string;
}

export default function BrandLogo({ classes }: IBrandLogoProps) {
  return (
    <Link href="/">
      <a className={classes}>
        <Image src={FormPayLogo} alt="FormPay logo" layout="intrinsic" />
      </a>
    </Link>
  );
}
