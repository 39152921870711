import React, { ReactElement, Fragment } from "react";
import { Background, Nav } from "../../components/layout";
import Footer from "../../components/landers/Footer";

interface IPageWrapper {
  children: ReactElement;
  classes?: string;
}

export default function PageWrapper({ children, classes }: IPageWrapper) {
  return (
    <Background classes={classes}>
      <Fragment>
        {/* {router.route !== PromoBannerContent.route &&
          <PromoBanner content={PromoBannerContent} />
        } */}
        <Nav />
        {children}
        <Footer />
      </Fragment>
    </Background>
  );
}
