export enum NextAppEnvEnum {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
  STAGING = "staging",
  TEST = "test",
}

/**
 * @description Define the breakpoints at which the layout will change, adapting to different screen sizes.
 */
export enum ViewPortSize {
  GRID_MIN_WIDTH = 302,
  SCREEN_SM_MIN = 576,
  SCREEN_SMMD_MIN = 600,
  SCREEN_MD_MIN = 768,
  SCREEN_LG_MIN = 992,
  SCREEN_XL_MIN = 1200,
  SCREEN_LG_MAX = ViewPortSize.SCREEN_XL_MIN - 1,
}

export enum ParentBlockTypeEnum {
  Input = "input",
  Image = "image",
  ImageUpload = "image_upload",
  Radio = "radio",
  TextArea = "textarea",
  CheckBox = "checkbox",
  Dropdown = "dropdown",
  Paragraph = "paragraph",
}

export enum ParentBlockSubTypeEnum {
  Email = "email",
  Date = "date",
  Tel = "tel",
  Text = "text",
  Time = "time",
  QuantityDropdown = "quantity_dropdown",
}

export enum QueryKeyEnum {
  SectionBlocks = "sectionBlocks",
  ParentBlocks = "parentBlocks",
  CheckBoxItemChildBlocks = "checkBoxItemChildBlocks",
  RadioButtonChildBlocks = "radioButtonChildBlocks",
  FormContactEmail = "formContactEmail",
  Orders = "orders",
  Form = "form",
  User = "user",
}

export enum ParentBlockValidationEnum {
  Email = "email",
  IsRequired = "is_required",
  CheckboxCount = "checkbox_count",
  MaxCharCount = "max_char_count",
  PhoneNumber = "phone_number",
  ZipCode = "zip_code",
  unavailableDeliveryDatepickerDays = "unavailable_delivery_datepicker_days",
  unavailablePickupDatepickerDays = "unavailable_pickup_datepicker_days",
}

export enum UrlSlugKeyWordEnum {
  "/registrations" = "registrations",
  "/art" = "art",
  "/food" = "food",
}

export enum OrderStatusEnum {
  Open = "Open",
  InProgress = "In progress",
  Complete = "Complete",
  Cancelled = "Cancelled",
}

export enum OrderStatusKeyEnum {
  Open = "open",
  InProgress = "in_progress",
  Complete = "delivered",
  Cancelled = "cancelled",
}

export enum PaymentTypeEnum {
  CreditCard = "Credit Card",
  Cash = "Cash",
}

export enum PaymentTypeKeyEnum {
  CreditCard = "stripe",
  Cash = "cash",
}

export enum PaymentStatusEnum {
  Unpaid = "Unpaid",
  PaymentPending = "Pending",
  Paid = "Paid",
  Refunded = "Refunded",
}

export enum PaymentStatusKeyEnum {
  Unpaid = "unpaid",
  PaymentPending = "payment_pending",
  Paid = "paid",
  Refunded = "refunded",
}

export enum DaysEnum {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum DropdownTypeEnum {
  /**
   * @dev corresponds to dropdowns that may have a SINGLE child block with the SAME labels (therefore :max_quantity should be present on the ChildBlock instance, which corresponds directly to the dropdown quantity options. The price should be the same, as one ChildBlock instance is used and should apply a multiplier based on the selected quantity.)
   *
   */
  Quantity = "quantity",
  /**
   * @dev corresponds to dropdowns that may have MULTIPLE child blocks with DIFFERING labels (prices may or may not be the same, since multiple ChildBlock instances are used)
   */
  Default = "default",
}

export enum TableHeaderArrowDirectionEnum {
  UP = "up",
  DOWN = "down",
  DOWN_UP = "down-up",
}

/**
 * @description used for dynamic sorting on the IOrder interface
 */
export enum OrderTableHeaderSortParamEnum {
  ORDER_NUMBER = "order_number",
  ORDER_STATUS = "fulfillment_status",
  FULFILLMENT_METHOD = "fulfillment_method",
  FULFILLMENT_DATE = "sortable_fulfillment_date",
}

export enum FulfillmentMethodEnum {
  DELIVERY = "delivery",
  PICKUP = "pickup",
}

export enum SideBarNavLabelKeysEnum {
  ORDERS = "orders",
  YOUR_STORE = "your_store",
  LOGOUT = "logout",
}

export namespace FormNS {
  export enum Status {
    DRAFT = "draft", // initial draft
    PUBLISHED = "published",
    EDIT = "edit", // previously published, but being updated
  }
}

export namespace FulfillmentSettingsNS {
  export enum PickupDetailsEnum {
    PREDETERMINED = "predetermined",
    ARRANGED = "arranged",
  }

  export enum SuperBlockKeyEnum {
    DELIVERY_INFO = "delivery_info",
    PICKUP_INFO = "pickup_info",
  }

  export enum DeliveryFeeModeEnum {
    FLAT = "flat",
    CITY = "city",
    STATE_PROVINCE = "state_province",
  }
}

export enum StaticSuperBlockKeyEnum {
  DELIVERY_INFO = "delivery_info",
  PICKUP_INFO = "pickup_info",
  CONTACT_INFO = "contact_info",
}

export enum StaticBlockAttributeNameEnum {
  // pickup info section help text
  PICKUP_SECTION_HELP_TEXT = "pickup_info_static_section_help_text",
  PICKUP_DATE_HELP_TEXT = "pickup_date_help_text",
  PICKUP_TIME_HELP_TEXT = "pickup_time_help_text",
  // pickup info disable date/time
  DISABLE_PICKUP_DATE_INFO = "disable_pickup_date_info",
  DISABLE_PICKUP_TIME_INFO = "disable_pickup_time_info",
  // delivery info section help text
  DELIVERY_SECTION_HELP_TEXT = "delivery_info_static_section_help_text",
  DELIVERY_DATE_HELP_TEXT = "delivery_date_help_text",
  DELIVERY_TIME_HELP_TEXT = "delivery_time_help_text",
  // delivery info disable date/time
  DISABLE_DELIVERY_DATE_INFO = "disable_delivery_date_info",
  DISABLE_DELIVERY_TIME_INFO = "disable_delivery_time_info",
  // fulfillment info section help text (radio options)
  FULFILLMENT_SECTION_HELP_TEXT = "fulfillment_info_static_section_help_text",
  FULFILLMENT_HELP_TEXT = "fulfillment_help_text",
  PICKUP_HELP_TEXT = "pickup_help_text",
  DELIVERY_HELP_TEXT = "delivery_help_text",
  // contact info
  MAILING_LIST_OPT_IN = "mail_opt_in",
  // contact fields
  FIRST_NAME = "first_name",
  PHONE_NUMBER = "phone_number",
  EMAIL = "email",
  // pickup/delivery order fields
  PICKUP_DATE = "pickup_date",
  DELIVERY_DATE = "delivery_date",
  FULFILLMENT_TIME = "fulfillment_time",
  PICKUP_SAME_DAY_ENABLED = "pickup_same_day_selection_enabled",
  DELIVERY_SAME_DAY_ENABLED = "delivery_same_day_selection_enabled",
  // delivery address fields
  PRIMARY_STREET_ADDRESS = "primary_street_address",
  SECONDARY_STREET_ADDRESS = "secondary_street_address",
  CITY = "city",
  STATE_PROVINCE = "state_province",
  COUNTRY = "country",
  ZIP_POSTAL_CODE = "zip_postal_code",
}
