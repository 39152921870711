import React from "react";
import Link from 'next/link';

import { useTranslation } from "react-i18next";
import BrandLogo from './BrandLogo';
import SignUpButton from "../landers/SignUpButton";

const NavItems = [{
  label: 'Pricing',
  url: '/#pricing'
},{
  label: 'Blog',
  url: '/blog'
},{
  label: 'Login',
  url: '/login'
}]

interface INavProps {
  hideNavItems?: boolean;
  classes?: string;
}

export default function Nav({ classes, hideNavItems }: INavProps) {
  const { t } = useTranslation();

  return (
    <nav className={`navbar ${classes ? classes : ''}`}>
      <div className="container">
        <BrandLogo classes="navbar-brand" />
        {!hideNavItems &&
          <ul className="list-unstyled list-inline">
            {NavItems.map((item, index) => (
              <li className="list-inline-item navbar__item" key={index}>
                <Link href={item.url}><a className="navbar__item-link">{t(item.label)}</a></Link>
              </li>
            ))}
            <SignUpButton label="Sign up" gtagLabel="nav" classes="ml-3" />
          </ul>
        }
      </div>
    </nav>    
  );
}
